import React from 'react'
import Navbar from './Navbar'

function Guidelines(){
  return (
    <div>
    {/* <h1>AddVoters</h1> */}
    <figure class="text-center">
  <blockquote class="blockquote">
  <h1 class="display-3" id='headingadd'>Guidelines</h1>
  <ul style={{marginTop:'190px'}}>
    <li>Enter all the valid details.</li>
    <li>Enter the details according to your official id.</li>
  </ul>
  </blockquote>
  {/* <figcaption class="blockquote-footer">
    Someone famous in <cite title="Source Title">Source Title</cite>
  </figcaption> */}
</figure>
<div style={{marginLeft:'-1px',marginTop:'-362px'}}>
       <Navbar />
    </div>

</div>
  )
}
export default Guidelines